import React from "react"

const CategoryBadge = ({ title }) => {
  switch (title) {
    case "Case Study":
      return (
        <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-semibold leading-4 bg-green-100 text-green-800 mr-3">
          {title}
        </span>
      )
    case "Update":
      return (
        <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-semibold leading-4 bg-orange-100 text-orange-800 mr-3">
          {title}
        </span>
      )
    case "Developer":
      return (
        <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-semibold leading-4 bg-purple-100 text-purple-800 mr-3">
          {title}
        </span>
      )
    default:
      return (
        <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-semibold leading-4 bg-gray-100 text-gray-800 mr-3">
          {title}
        </span>
      )
  }
}

export default CategoryBadge
