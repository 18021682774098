import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { serializers } from "../utils/blocks"
import Layout from "../components/Layouts/Base"
import SEO from "../components/seo"
import CategoryBadge from "../components/CategoryBadge"
import Sidebar from "../components/Integrations/Sidebar"

export const query = graphql`
  query SingleBlogPostQuery($slug: String) {
    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      skip: 0
      limit: 100
      filter: { showInSidebar: { eq: true } }
    ) {
      edges {
        node {
          slug {
            current
          }
          title
        }
      }
    }
    sanityPost(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      categories {
        title
      }
      title
      seoTitle
      seoDescription
      publishedAt(formatString: "MMM Do")
      publishedAtFull: publishedAt
      author {
        name
        image {
          asset {
            fixed(width: 40, height: 40) {
              ...GatsbySanityImageFixed
            }
          }
        }
      }
      mainImage {
        asset {
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
          }
        }
      }
      imageCaption
      _rawBody
    }
  }
`

const BlogPostPage = ({ data }) => {
  const {
    title,
    slug,
    categories,
    seoTitle,
    seoDescription,
    publishedAt,
    publishedAtFull,
    author,
    mainImage,
    imageCaption,
    _rawBody,
  } = data.sanityPost
  const posts = data.allSanityPost.edges

  return (
    <Layout>
      <SEO
        title={seoTitle || title}
        description={seoDescription}
        imageURL={mainImage && mainImage.asset.fluid.src}
        ogType="blog"
      />

      <div className="max-w-5xl mx-auto px-6 py-10 lg:pb-16">
        <div className="md:grid md:grid-cols-12 md:gap-10">
          <Sidebar posts={posts} />
          <div className="md:col-span-8">
            <article
              className="mb-12 lg:mb-16"
              itemScope
              itemType="http://schema.org/BlogPosting"
            >
              <div className="pb-8">
                <h1
                  itemProp="name headline"
                  className="true }
                  text-2xl font-display text-black font-bold leading-8 md:text-4xl sm:leading-tight"
                >
                  {title}
                </h1>

                {/* <div className="flex items-center justify-center mt-4 md:text-lg text-gray-500">
                  <Img
                    fixed={author.image.asset.fixed}
                    alt={author.name}
                    className="mr-4 rounded-full"
                  />
                  <span className="leading-tight">
                    <span itemProp="author">{author.name}</span> on{" "}
                    <time itemProp="datePublished" dateTime={publishedAtFull}>
                      {publishedAt}
                    </time>
                  </span>
                </div> */}

                {/* <div className="flex justify-center mt-4 md:mt-6">
                  {categories.map(({ title }) => (
                    <CategoryBadge key={title} title={title} />
                  ))}
                </div> */}
              </div>

              {mainImage && (
                <div className="text-container">
                  <figure className="mb-12">
                    <Img
                      fluid={mainImage.asset.fluid}
                      alt={imageCaption || title}
                    />
                    {imageCaption && (
                      <figcaption className="text-gray-400 text-lg py-2 text-center md:text-left">
                        {imageCaption}
                      </figcaption>
                    )}
                  </figure>
                </div>
              )}

              <div itemProp="articleBody" className="article-body">
                <BlockContent
                  className="text-container"
                  blocks={_rawBody}
                  serializers={serializers}
                  projectId={process.env.GATSBY_SANITY_PROJECT}
                  dataset={process.env.GATSBY_SANITY_DATASET}
                />
              </div>
            </article>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostPage
